import { Pipe, PipeTransform } from '@angular/core';

const PLACEHOLDER = '—';
type ValueType = string | number | undefined | null | unknown;

/** Sets a placeholder if value is null or empty string. */
@Pipe({
	name: 'ygmcNullable',
	standalone: true,
})
export class NullablePipe implements PipeTransform {

	/** @inheritdoc */
	public transform(value: ValueType): string {
		if (this.isStringOrNumber(value)) {
			return value.toString().trim() === '' ? PLACEHOLDER : value.toString();
		}
		return PLACEHOLDER;
	}

	private isStringOrNumber(value: ValueType): value is string | number {
		return typeof value === 'string' || typeof value === 'number';
	}
}
